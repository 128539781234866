import { theme } from 'globalStyles'
import { number, oneOf } from 'prop-types'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  ${({ backgroundColor }) => backgroundColor && `background-color: var(--color-${backgroundColor});`}

  ${theme.breakpoints.phone} {
    max-width: ${({ maxWidth = 792 }) => maxWidth}px;
  }

  @media (min-width: 1024px) {
    padding: 30px 1rem;
  }
`

Container.propTypes = {
  maxWidth: number,
  backgroundColor: oneOf([
    'primary',
    'primary-light',
    'primary-lighter',
    'white',
    'light-grey',
    'light-dark-grey',
    'grey',
    'dark-gray',
    'off-black',
    'black',
    'light-black',
    'success',
    'success-light',
    'pending',
    'green',
    'green2',
  ]),
}
