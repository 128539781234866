import styled from 'styled-components'
import './fonts.css'
import Footer from './components/Footer/Footer'
import Navigation from './components/Navigation/Navigation'
import GlobalStyles from './globalStyles'
import Routes from './routes'
import { BottomSection } from './components/BottomSection'
import { useLocation } from 'react-router'
import { useEffect, useRef } from 'react'
import { useAppTranslation } from 'i18n/hooks'
import { useDocumentTitle } from 'hooks/useDocumentTitle'
import { useSyncEmailVerification } from 'hooks/useSyncEmailVerification'
import { Banner } from 'components/Banner'
import 'react-phone-number-input/style.css'
import { Cookie } from 'components/Cookie'

function App() {
  const location = useLocation()
  const { t } = useAppTranslation()
  const { setTitle } = useDocumentTitle()
  const prevPage = useRef([])

  useSyncEmailVerification()

  // This will scroll window to the top when page'll change
  useEffect(() => {
    if (prevPage.current[0]?.path !== location.pathname) {
      window.scrollTo(0, 0)

      return () => {
        prevPage.current = [prevPage.current.pop(), { path: location.pathname, scrollTop: window.scrollY }]
      }
    }

    return () => {
      prevPage.current = [{ path: location.pathname, scrollTop: window.scrollY }]
    }
  }, [location.pathname])

  useEffect(() => {
    setTitle(t('holmastoAuction'))
  }, [t, setTitle])

  return (
    <SiteContainer>
      <GlobalStyles />
      <Navigation />
      <Banner />
      <Routes />
      {!location.pathname.startsWith('/not-found') && !location.pathname.startsWith('/accept') && <BottomSection />}
      <Footer />
      <Cookie />
    </SiteContainer>
  )
}

export default App

const SiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 100vh;
`
