import { useCurrendUserProfile } from 'hooks/data/useWishlist'
import { any } from 'prop-types'

const { createContext, useMemo } = require('react')

export const ProfileContext = createContext({ profile: null, user: null, loading: true, error: null })

export const ProfileProvider = ({ children }) => {
  const { profile, user, loading, error } = useCurrendUserProfile()

  const value = useMemo(() => ({ profile, user, loading, error }), [profile, user, loading, error])

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
}

ProfileProvider.propTypes = {
  children: any,
}
