import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from '../../globalStyles'

//TODO: Extend this component to include affordances for Media on the right side
function MediaText({ children, backgroundImage, backgroundColor }) {
  return (
    <>
      <MediaTextContainer backgroundColor={backgroundColor}>
        <MediaContainer backgroundImage={backgroundImage} />
        <TextContainer>{children}</TextContainer>
      </MediaTextContainer>
      <div className="brand-navigation"></div>
    </>
  )
}

MediaText.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
}

export default MediaText

const MediaTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.backgroundColor || 'var(--color-primary)'};
  color: var(--color-white);
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  text-align: center;
  align-items: center;
  gap: 2rem;
  h3,
  p {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 1024px) {
    padding: 5rem 0;
    padding-left: 6.375rem;
    align-items: flex-start;
    text-align: left;
    h3,
    p {
      margin-bottom: 2rem;
      max-width: 486px;
    }
  }
`

const MediaContainer = styled.div`
  background-image: ${props => `url(${props.backgroundImage})`};
  background-size: cover;
  min-height: 250px;
  ${theme.breakpoints.tablet} {
    min-width: 50%;
  }
`
