import { Text } from 'components/Ui-Kits/Text'
import { useDateCountdown } from 'hooks/useDateCountdown'
import { useAppTranslation } from 'i18n/hooks'
import { instanceOf, string } from 'prop-types'
import styled from 'styled-components'
import { BannerWrap, LinkButton } from './Banner.styles'

export const CountdownBanner = ({ startDate, id }) => {
  const { t } = useAppTranslation()
  const countDown = useDateCountdown(startDate)

  return (
    <BannerWrap color="primary">
      <Text size={1} color="white">
        {t('liveAuctionStartsIn')} <CountDownWrap>{countDown}</CountDownWrap>
      </Text>
      <LinkButton to={`/auction/${id}/live`}>{t('goToLobby')}</LinkButton>
    </BannerWrap>
  )
}

CountdownBanner.propTypes = {
  startDate: instanceOf(Date).isRequired,
  id: string,
}

const CountDownWrap = styled.span`
  width: 60px;
  display: inline-block;
`
