import { Text } from 'components/Ui-Kits/Text'
import { string } from 'prop-types'
import styled from 'styled-components'

export const Article = ({ url, image, title }) => {
  return (
    <Wrap href={url} target="_blank">
      <Image src={image} alt={title} />
      <TitleWrap>
        <StyledText color="primary" size={1.25}>
          {title}
        </StyledText>
      </TitleWrap>
    </Wrap>
  )
}

Article.propTypes = {
  title: string,
  url: string,
  image: string,
}

const Wrap = styled.a`
  display: block;
  text-decoration: none;
`
const Image = styled.div`
  width: 100%;
  display: block;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  padding-bottom: 66%;
  height: 0;
`

const TitleWrap = styled.div`
  height: 95px;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
`

const StyledText = styled(Text)`
  display: inline-block;
  max-height: 48px;
  overflow: hidden;
`
