import { mainMenuUrls } from 'constants/index'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useAppTranslation } from 'i18n/hooks'
import { useEffect } from 'react'
import { List } from '../Navigation.styled'
import { MainMenuItem } from './MainMenuItem'

export const MainMenu = () => {
  const {
    i18n: { language },
  } = useAppTranslation()
  const [menuItems, setMenuItems] = useLocalStorage(`main-menu-${language}`, [])

  useEffect(() => {
    const controller = new AbortController()
    fetch(mainMenuUrls[language], {
      method: 'GET',
      signal: controller.signal,
    })
      .then(result => result.json())
      .then(data => {
        if (!Array.isArray(data)) return
        setMenuItems(
          data
            .filter(({ menu_item_parent }) => menu_item_parent === '0')
            .map(item => ({
              ...item,
              items: data.filter(({ menu_item_parent }) => parseInt(menu_item_parent) === item.ID),
            }))
        )
      })
      .catch(reason => {
        if (reason.name !== 'AbortError') {
          throw reason
        }
      })

    return () => {
      controller.abort()
    }
  }, [language, setMenuItems])

  return (
    <List>
      {menuItems.map(({ ID, title, url, items }) => (
        <MainMenuItem key={ID} title={title} url={url} items={items} />
      ))}
    </List>
  )
}
