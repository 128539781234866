import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { Router } from 'react-router'
import { browserHistory } from './browserHistory'
import 'genero-design-system/dist/gds/gds.css'
import App from './App'
import Taxonomies from './decorators/Taxonomies'
import { AlertProvider } from 'decorators/Alert'
import { ProfileProvider } from 'decorators/User'
import './sentry'
import './dayjs'

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ProfileProvider>
        <Router history={browserHistory}>
          <Taxonomies>
            <AlertProvider>
              <App />
            </AlertProvider>
          </Taxonomies>
        </Router>
      </ProfileProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
