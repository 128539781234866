import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

function Button(props) {
  const { color, secondary, large, children, ...other } = props

  return (
    <StyledButton color={color} large={large} secondary={secondary} {...other}>
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['white', 'primary']),
  secondary: PropTypes.bool,
  large: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

export default Button

export const StyledButton = styled.button`
  background-color: ${props => `var(--color-${props.color})`};
  color: ${props => (props.color === 'white' ? 'var(--color-primary)' : 'var(--color-white)')};
  border: none;
  padding: ${props => (props.large ? '16px 24px' : '10px 16px')};
  font-size: ${props => (props.large ? '22px' : '16px')};
  letter-spacing: 1.1px;
  line-height: ${props => (props.large ? '26px' : '20px')};
  font-weight: bold;
  text-transform: uppercase;
  box-sizing: border-box;
  ${({ fullWidth }) => fullWidth && `width: 100%;`}

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5 !important;
  }
  &:hover {
    opacity: 0.8;
    transition: all 0.3s ease-out;
  }
  ${props =>
    props.secondary &&
    `
    background-color: transparent;
    color: var(--color-${props.color});
    padding: ${props.large ? '14px 24px' : '8px 16px'};
    border: 2px solid var(--color-${props.color});
  `}
`
