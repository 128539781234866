import { Button } from 'components/Button'
import { Heading, Text } from 'components/Ui-Kits/Text'
import { useLiveAuctionCurrentProduct } from 'hooks/data/auction/useAuctionProducts'
import { useAppTranslation } from 'i18n/hooks'
import { string } from 'prop-types'
import { useHistory } from 'react-router'
import styled from 'styled-components'

export const LiveBidBox = ({ auctionId }) => {
  const { product } = useLiveAuctionCurrentProduct(auctionId)
  const history = useHistory()
  const goToAuction = () => history.push(`/auction/${auctionId}/live`)
  const { t } = useAppTranslation()

  return (
    <Wrap>
      <Head>
        <Bullet />
        <TextWrap>
          <Heading color="white" size={1.375} mt={0} mb={0.75}>
            {t('auctionInProgress')}
          </Heading>
          <Text color="white" mt={0} mb={0}>
            {t('item')} {product?.sort + 1}
          </Text>
        </TextWrap>
      </Head>
      <Button color="white" onClick={goToAuction} large>
        {t('participate')}
      </Button>
    </Wrap>
  )
}

LiveBidBox.propTypes = {
  auctionId: string.isRequired,
}

const Wrap = styled.div`
  width: 100%;
  border: 2px solid var(--color-white);
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  margin-top: 2rem;
`
const Head = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
`

const Bullet = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--color-green);
  margin-right: 1rem;
`

const TextWrap = styled.div`
  flex: 1;
`
