import styled from 'styled-components'
import bg from '../../assets/images/bottom-banner-bg.jpg'
import DisplayGrid from '../DisplayGrid/DisplayGrid'
import { Item } from './Item'
import { faStar, faCoins, faStore } from '@fortawesome/pro-regular-svg-icons'

export const BottomSection = () => {
  return (
    <Wrap>
      <DisplayGrid rows={1} columns={3}>
        <Item
          icon={faCoins}
          link="#"
          title="OSTAMME KULTAA"
          description="Ostamme kultaa kaikissa muodoissa, kultakoruista kultaharkkoihin ja romukultaa. Kultakauppaa luottamuksella jo vuodesta 1949."
        />
        <Item
          icon={faStar}
          title="ASIANTUNTIJUUS"
          description="Palvelumme kulmakiviä ovat eri toimialojemme asiantuntijuus ja laadukas asiakaslähtöinen palvelu."
        />
        <Item
          icon={faStore}
          title="LIIKKEEMME"
          description="Palvelemme Teitä arkisin 10 - 17 ja lauantaisin 10 - 15 osoitteessa Aleksanterinkatu 50 Helsinki ."
        />
      </DisplayGrid>
    </Wrap>
  )
}

const Wrap = styled.section`
  min-height: 409px;
  padding: 32px 0;
  background-image: url('${bg}');
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
`
