import { Link } from 'components/Ui-Kits/Text/Link'
import styled from 'styled-components'

export const BannerWrap = styled.div`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: var(--color-${({ color }) => color});
  margin-bottom: 0.5rem;
`

export const LinkButton = styled(Link)`
  color: var(--color-primary);
  display: inline-block;
  padding: 0.5rem;
  background-color: var(--color-white);
`
