import { doc } from 'firebase/firestore'
import { useMemo } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { db } from '../../data/firebase'
import { useCurrentUser } from '../useCurrentUser'

/**
 * @typedef {Object} UseCurrendUserDataReturn
 * @property {Profile|undefined} profile
 * @property {User|undefined} user
 * @property {boolean} loading
 *
 */
export const useCurrendUserProfile = () => {
  const { user, loading: userLoading } = useCurrentUser()
  const [profileDoc, profileLoading, error] = useDocument(user?.uid ? doc(db, 'profiles', user.uid) : undefined)

  const loading = useMemo(() => {
    if (profileLoading || userLoading) return true

    return !!user && !profileDoc
  }, [userLoading, profileLoading, profileDoc, user])

  const profile = useMemo(() => profileDoc?.data(), [profileDoc])

  return { profile, loading, user, error }
}
