import { functions } from '../data/firebase'
import { httpsCallable } from 'firebase/functions'

/**
 * @typedef {Object} Props
 * @property {string} id
 * @property {'accepted'|'declined'} status
 *
 * @param {Props} data
 */
export const acceptOrDeclineProduct = httpsCallable(functions, 'acceptOrDeclineProduct')

export const createBid = httpsCallable(functions, 'callCreateBid')
export const createWishlistItem = httpsCallable(functions, 'createWishlistItem')
export const removeWishlistItem = httpsCallable(functions, 'removeWishlistItem')
export const createUserLiveBid = httpsCallable(functions, 'callCreateUserLiveBid')
export const getWebshopOrders = httpsCallable(functions, 'callGetWebshopOrders')
export const getNetsRedirectUrl = httpsCallable(functions, 'callGetNetsRedirectUrl')
export const getNetsData = httpsCallable(functions, 'callGetNetsData')
export const registerUser = httpsCallable(functions, 'callRegisterUser')
export const deleteMyDocument = httpsCallable(functions, 'callDeleteMyDocument')
export const submitProfile = httpsCallable(functions, 'callSubmitProfile')
export const changeProfileLanguage = httpsCallable(functions, 'changeProfileLanguage')
export const getPaytrailFormData = httpsCallable(functions, 'callGetPaytrailFormData')
export const getPaytrailToken = httpsCallable(functions, 'callGetPaytrailToken')
export const getWebshopShippingMethods = httpsCallable(functions, 'callGetWebshopShippingMethods')
export const sendResetLinkEmail = httpsCallable(functions, 'callSendResetLinkEmail')
export const updateProfileDataFunc = httpsCallable(functions, 'callUpdateProfileData')
export const syncProfileVerificationStatus = httpsCallable(functions, 'callSyncProfileVerificationStatus')
export const acceptOrRejectReception = httpsCallable(functions, 'callAcceptOrRejectReception')
export const getSellerProductPurchaseInvoice = httpsCallable(functions, 'callGetSellerProductPurchaseInvoice')
export const getSellerReceptionPurchaseInvoice = httpsCallable(functions, 'callGetSellerReceptionPurchaseInvoice')
export const generateSellerProductsPdfForAuction = httpsCallable(functions, 'callGenerateSellerProductsPdfForAuction')
export const sendVerifyEmailUrl = httpsCallable(functions, 'callSendVerifyEmailUrl')
export const approveProposal = httpsCallable(functions, 'callApproveProposal')
export const getCheckoutUrlWithToken = httpsCallable(functions, 'getCheckoutUrlWithToken')
export const verifyPendingEmailUpdate = httpsCallable(functions, 'callVerifyPendingEmailUpdate')
export const setUserPasswordHandler = httpsCallable(functions, 'callSetUserPasswordHandler')
export const checkPasswordResetLink = httpsCallable(functions, 'callCheckPasswordResetLink')
