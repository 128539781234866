import DisplayGrid from 'components/DisplayGrid/DisplayGrid'
import ProductTeaser from 'components/ProductTeaser/ProductTeaser'
import Section from 'components/Section/Section'
import { Heading, HeadingMargin } from 'components/Ui-Kits/Text'
import { useFeaturedAuctionProducts } from 'hooks/data/auction/useAuctionProducts'
import { useGetTranslated } from 'hooks/useGetTranslated'
import { useAppTranslation } from 'i18n/hooks'
import { string } from 'prop-types'

export const Products = ({ auctionId }) => {
  const { t } = useAppTranslation()
  const { products } = useFeaturedAuctionProducts(auctionId)
  const getTranslated = useGetTranslated()

  return (
    <Section background="var(--color-grey)">
      <Heading color="primary" mt={HeadingMargin.NONE} mb={HeadingMargin.LARGE}>
        {t('upcomingAuction')}
      </Heading>
      <DisplayGrid columns={4} rows={1}>
        {products?.map(product => (
          <ProductTeaser
            key={product.id}
            name={getTranslated(product?.name)}
            category={getTranslated(product?.category?.label)}
            image={product?.featuredImage?.url}
            price={product?.technicalDetails?.AuctionMinimumPrice}
            path={`/auction/${auctionId}/${product.id}`}
            auctionId={auctionId}
          />
        ))}
      </DisplayGrid>
    </Section>
  )
}

Products.propTypes = {
  auctionId: string.isRequired,
}
