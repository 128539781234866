import { Container } from 'components/Container'
import { TopBanner } from 'components/TopBanner'
import { Heading, Paragraph } from 'components/Ui-Kits/Text'

export const TextPage = () => {
  return (
    <>
      <TopBanner
        background="https://picsum.photos/1440/500/"
        title="Huutokauppasäännöt"
        links={[
          { link: '#', label: 'Etusivu' },
          { link: '#', label: 'huutokaupa' },
          { link: '#', label: 'Huutokauppasäännöt' },
        ]}
      />
      <Container>
        <Heading color="primary">Huutokauppasäännöt</Heading>
        <Paragraph>
          Huutokauppa on julkinen ja vapaaehtoinen. Luettelossa mainitut hinnat ovat minimihintoja €, joiden alle
          kohteita ei myydä. Luettelon tiedot on laadittu suurinta huolellisuutta noudattaen. Kohteet myydään siinä
          kunnossa kun ne ovat myyntihetkellä ja koska kohteet ovat nähtävänä luettelossa ilmoitettuna näyttöaikana
          Holmaston toimitiloissa sekä huutokauppatilaisuudessa, valituksia ei huomioida vasaralyönnin jälkeen.
        </Paragraph>
        <Paragraph>
          Kellot ja kaikki mekaaniset ja vastaavanlaiset esineet myydään keräilykohteina/taide-esineinä, eikä Holmasto
          ota vastuuta niiden teknisestä kunnosta tai toimivuudesta.
        </Paragraph>
        <Heading color="primary">Toimeksiannot sekä ostajan muut velvollisuudet</Heading>
        <Paragraph>
          Säännöt koskevat kaikkia ennakkoon, huutokauppatilaisuudessa, Internetin kautta tai muulla tavalla annettuja
          tarjouksia. Tarjouksen antaja (jäljempänä Ostaja) voi halutessaan jättää ostotarjouksia Rahaliike Holmaston
          (jäljempänä Holmasto) verkkopalvelun kautta: https://www.holmasto.fi.
        </Paragraph>
        <Paragraph>
          Huutokauppoihin on mahdollista osallistua myös reaaliaikaisesti, ohjeet Holmaston verkkosivuilla. Tarjouksia
          on myös mahdollista antaa kirjeitse, sähköpostitse tai Faxilla numeroon 09-666628 sekä puhelimitse 09-666500,
          huutokauppapäivänä myös 050 5241876. Riippumatta siitä, aikooko osallistua huutokauppaan realiaikaisesti,
          suosittelemme joka tapauksessa tekemään ennakkotarjouksia halutuille kohteille yllättävien tieto-liikenne tai
          järjestelmien katkoksien varalta.
        </Paragraph>
        <Paragraph>
          Osallistuminen edellyttää rekisteröitymisen. https://demo-liveauction.holmasto.fi/registration/join
        </Paragraph>
        <Paragraph>
          Ostajan hyväksyttyä tarjouksensa, lähettämällä tarjouksen Holmaston verkkopalvelussa “hyväksyn tarjouksen”
          -painikkeella, tarjous on sitova eikä enää pienennettävissä tai perutettavissa mutta kyllä korotettavissa.
        </Paragraph>
        <Paragraph>
          Tehdyistä tarjouksista vastaa kaikilta osin tarjouksen tehnyt henkilö, myös hänen toimiessaan toisen henkilön
          toimeksiannosta. Lyötyyn hintaan lisätään huutomaksu 22 % (Marginaaliveronalainen myynti).
        </Paragraph>
        <Paragraph>
          Holmasto pidättää itsellään oikeuden kaikissa tilanteissa olla hyväksymättä tehtyjä tarjouksia syytä erikseen
          ilmoittamatta. Holmasto ei vastaa toimintansa johdosta, tai muusta syystä, poisjääneistä tai huomioimatta
          jääneistä tarjouksista eikä ole myöskään korvausvelvollinen ostajan mahdollisesti kärsimästä välittömästä tai
          välillisestä vahingosta.
        </Paragraph>
        <Paragraph>
          Huutokaupan aikana ja sen jälkeen verkkosivuillamme ilmoitetut myyntihinnat ovat teknisistä syistä
          tarkistamattomia ja näin ollen, äärimmäisen harvinaisissa tapauksissa, saattaa virheitä esiintyä. Holmasto
          pidättää itsellään oikeuden korjata verkkosivuillaan ilmenneitä virheitä huutokaupan aikana ja sen jälkeenkin.
          Verkkosivuillamme ilmoitetut myyntihinnat eivät e.m. syystä ole Holmastoa sitovia. Lopullinen hinta
          ilmoitetaan ostajalle aina erikseen Holmaston toimesta.
        </Paragraph>
      </Container>
    </>
  )
}
