import { useAppTranslation } from 'i18n/hooks'
import { useCallback } from 'react'

export const useGetTranslated = () => {
  const {
    i18n: { language },
  } = useAppTranslation()

  return useCallback(
    item => {
      return item?.[language] || item?.en
    },
    [language]
  )
}
