import en from './dictionaries/en'
import fi from './dictionaries/fi'
import sv from './dictionaries/sv'

export const translations = Object.freeze({
  en: { translations: en },
  fi: { translations: fi },
  sv: { translations: sv },
})

export const languages = Object.freeze({
  EN: 'en',
  FI: 'fi',
  SV: 'sv',
})
