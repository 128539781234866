import { syncProfileVerificationStatus } from 'cloudFunctions'
import { useEffect, useRef } from 'react'
import { useProfile } from './useProfile'

/**
 *
 * This hook checks if users email verification status is same as on the profile
 * If it's not same it calls syncProfileVerificationStatus to update email verified status
 * on profile
 *
 */
export const useSyncEmailVerification = () => {
  const { profile, user } = useProfile()
  const loadingRef = useRef(false)

  useEffect(() => {
    if (!user) return
    if ((useRef.current && !user) || !profile || user.emailVerified === profile.emailVerified) return

    loadingRef.current = true
    syncProfileVerificationStatus().finally(() => {
      loadingRef.current = false
    })
  }, [profile, user])
}
