import holmastoLogo from '../../assets/images/holmasto-logo.svg'

import './navigation.css'
import { NavigationWrap, Logo, MenusWrap, LogoWrap, List, ListItem, Icon } from './Navigation.styled'
import { MainMenu } from './MainMenu'
import { RightMenu } from './RightMenu'
import { Account } from './RightMenu/Account'
import { faBars } from '@fortawesome/pro-regular-svg-icons'
import { useState } from 'react'

function Navigation() {
  const [open, setOpen] = useState(false)

  return (
    <NavigationWrap>
      <LogoWrap>
        <a href="https://holmasto.fi/'">
          <Logo src={holmastoLogo} alt="logo" />
        </a>

        <List>
          <Account />
          <ListItem>
            <Icon icon={faBars} onClick={() => setOpen(open => !open)} />
          </ListItem>
        </List>
      </LogoWrap>
      <MenusWrap open={open}>
        <MainMenu />
        <RightMenu />
      </MenusWrap>
    </NavigationWrap>
  )
}

Navigation.propTypes = {}

export default Navigation
