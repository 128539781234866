import { useWishlistItemExists } from 'hooks/data/useWishlistItemExists'
import { useEffect, useState } from 'react'
import { faHeart as faHeartLight } from '@fortawesome/pro-regular-svg-icons'
import { faHeart } from '@fortawesome/pro-solid-svg-icons'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { string } from 'prop-types'
import { createWishlistItem, removeWishlistItem } from 'cloudFunctions'
import { useProfile } from 'hooks/useProfile'
import { useAppTranslation } from 'i18n/hooks'

export const AddToWishlist = ({ id, auctionId }) => {
  const hasWishlistItem = useWishlistItemExists(id)
  const [isWishlist, setIsWishlist] = useState(hasWishlistItem)
  const { user } = useProfile()
  const { t } = useAppTranslation()

  useEffect(() => {
    setIsWishlist(hasWishlistItem)
  }, [hasWishlistItem])

  const toggleWishlistItem = () => {
    if (!user) return alert(t('pleaseLogInToProceed'), 'danger')
    {
      setIsWishlist(state => !state)
      !isWishlist ? createWishlistItem({ itemId: id, auctionId }) : removeWishlistItem(id)
    }
  }

  return (
    <Wrap onClick={e => e.preventDefault()}>
      <Icon icon={isWishlist ? faHeart : faHeartLight} onClick={toggleWishlistItem} />
    </Wrap>
  )
}

AddToWishlist.propTypes = {
  id: string.isRequired,
  auctionId: string.isRequired,
}

const Icon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: var(--color-primary);
  cursor: pointer;
`
const Wrap = styled.div`
  width: 31px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  background-color: var(--color-grey);
  top: 0;
  left: 0;
  position: absolute;
`
