import { useContext, useMemo } from 'react'
import { TaxonomiesContext } from '../decorators/Taxonomies'
import { useAppTranslation } from '../i18n/hooks'

export const useTaxonomies = type => {
  const {
    i18n: { language },
  } = useAppTranslation()

  const taxonomies = useContext(TaxonomiesContext)

  return useMemo(() => {
    const list = taxonomies?.find(({ taxonomy }) => taxonomy === type)?.terms
    if (!list) return []

    return list.map(item => ({ label: item.name[language] ?? item.name.fi, value: item.term_id }))
  }, [type, taxonomies, language])
}
