import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

function DisplayGrid({ rows, columns, children }) {
  return (
    <GridContainer rows={rows} columns={columns}>
      {children}
    </GridContainer>
  )
}

DisplayGrid.propTypes = {
  children: PropTypes.node.isRequired,
  rows: PropTypes.number,
  columns: PropTypes.number,
}

export default DisplayGrid

const GridContainer = styled.div`
  display: grid;
  gap: 24px;
  width: 100%;
  max-width: 344px;
  margin: 0 auto;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    max-width: 1200px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
    grid-template-rows: ${props => `repeat(${props.rows}, 1fr)`};
  }
`
