import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

export const useDateCountdown = date => {
  const [countDown, setCountDown] = useState(() => dayjs.utc(Math.max(dayjs(date).diff(dayjs()), 0)).format('HH:mm:ss'))

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(dayjs.utc(Math.max(dayjs(date).diff(dayjs()), 0)).format('HH:mm:ss'))
    }, 1000)
    return () => clearInterval(interval)
  }, [date])

  return countDown
}
