import { Text } from 'components/Ui-Kits/Text'
import { useAppTranslation } from 'i18n/hooks'
import { string } from 'prop-types'
import { BannerWrap, LinkButton } from './Banner.styles'

export const AuctionBanner = ({ id }) => {
  const { t } = useAppTranslation()

  return (
    <BannerWrap color="success">
      <Text size={1} color="white">
        {t('liveAuctionIsHappeningNow')}
      </Text>
      <LinkButton to={`/auction/${id}/live`}>{t('goToLiveAuction')}</LinkButton>
    </BannerWrap>
  )
}

AuctionBanner.propTypes = {
  id: string,
}
