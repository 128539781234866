import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { changeProfileLanguage } from 'cloudFunctions'
import { Text } from 'components/Ui-Kits/Text'
import { useQuery } from 'hooks/query/useQuery'
import { useProfile } from 'hooks/useProfile'
import { useAppTranslation } from 'i18n/hooks'
import { useRef } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import { Icon, ListItem, SubList, SubListItem } from '../Navigation.styled'

export const LanguageSwitcher = () => {
  const { i18n } = useAppTranslation()
  const { profile } = useProfile()
  const { lang } = useQuery()
  const languageWasChanged = useRef(false)

  useEffect(() => {
    if (lang && ['en', 'fi', 'sv'].includes(lang)) {
      i18n.changeLanguage(lang)
    }
  }, [lang, i18n])

  useEffect(() => {
    if (!profile?.language || i18n.language === profile.language || languageWasChanged.current) return

    i18n.changeLanguage(profile.language)
  }, [profile, i18n])

  const handleLanguageChange = language => {
    i18n.changeLanguage(language)
    languageWasChanged.current = true

    if (profile) changeProfileLanguage(language)
  }

  return (
    <StyledListItem>
      <Text cursor="pointer">
        {i18n.language}
        <Icon icon={faChevronDown} />
      </Text>
      <SubList>
        {['fi', 'en', 'sv'].map(language => (
          <SubListItem key={language}>
            <Text as="button" onClick={() => handleLanguageChange(language)} cursor="pointer">
              {language.toLocaleUpperCase()}
            </Text>
          </SubListItem>
        ))}
      </SubList>
    </StyledListItem>
  )
}

const StyledListItem = styled(ListItem)`
  display: block !important;
  @media (max-width: 1024px) {
    & > ${Text} {
      display: none;
    }
    & > ${SubList} {
      display: flex;
      flex-direction: row;
      width: auto;
      & > ${SubListItem} {
        margin: 0 0.5rem;
        & ${Text} {
          padding: 0;
        }
      }
    }
  }
`
