import { Heading, HeadingSize } from 'components/Ui-Kits/Text'
import { useAppTranslation } from 'i18n/hooks'
import { useEffect, useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { debounce } from 'lodash'
import { Button } from 'components/Button'
import { useHistory } from 'react-router'
import bgImage from 'assets/images/404.jpg'

export const NotFound = () => {
  const { t } = useAppTranslation()
  const history = useHistory()
  const ref = useRef(null)
  const [minHeight, setMinHeight] = useState()
  const calculateHeight = useCallback(() => {
    if (window.innerWidth < 760) return 0
    return window.innerHeight - ref.current.previousSibling.offsetHeight
  }, [])

  useEffect(() => {
    setMinHeight(calculateHeight())
    const callback = debounce(() => setMinHeight(calculateHeight()), 400)

    window.addEventListener('resize', callback)

    return () => {
      window.removeEventListener('resize', callback)
      callback.cancel()
    }
  }, [calculateHeight])

  return (
    <Wrap ref={ref} minHeight={minHeight}>
      <Image src={bgImage} alt="Not found" />
      <Content>
        <Description>
          <Heading color="white" mt={0} mb={2} size={HeadingSize.SMALL}>
            404
          </Heading>
          <StyledHeader color="white" mt={0} mb={2} size={HeadingSize.BIG}>
            {t('sorry')},
          </StyledHeader>
          <Heading color="white" mt={0} mb={2} size={HeadingSize.SMALL}>
            {t('pageWasntFound')}.
          </Heading>

          <Button color="white" large onClick={() => history.push('/')}>
            PALAA ETUSIVULLE
          </Button>
        </Description>
      </Content>
    </Wrap>
  )
}

const Wrap = styled.div`
  min-height: ${({ minHeight }) => minHeight}px;
  background-color: var(--color-primary);
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;

  @media (min-width: 760px) {
    flex-direction: row;
  }
`
const Content = styled.div`
  max-width: 1200px;
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  & ${Heading}:first-child {
    opacity: 0.5;
  }

  @media (min-width: 1024px) {
    padding: 10px 30px;
  }
`
const Image = styled.img`
  display: block;
  width: 100%;
  padding: 0;

  @media (min-width: 760px) {
    padding: 10px 30px;
    position: absolute;
    min-height: 100%;
    left: 50%;
    top: 0;
    min-width: 50%;
    width: auto;
    height: 100%;
    margin: 0;
    padding: 0;
  }
`
const Description = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 20px;

  @media (min-width: 760px) {
    width: 50%;
    padding: 0;
    align-items: flex-start;
  }
`
const StyledHeader = styled(Heading)`
  font-size: 30px;

  @media (min-width: 760px) {
    font-size: 3.75rem;
  }
`
