import { bool, number } from 'prop-types'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { defaultPropTypes, TextStyle } from './Shared'

export const Link = styled(NavLink)`
  ${TextStyle}
  font-family: 'Atten New';
  font-size: ${({ size }) => size}rem;
  ${({ underline }) => underline && 'text-decoration: underline;'}
`
Link.propTypes = {
  ...defaultPropTypes,
  ...Link.propTypes,
  size: number,
  underline: bool,
}

Link.defaultProps = {
  size: 0.9375,
  color: 'black',
}
