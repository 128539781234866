import CategoryTeaser from 'components/CategoryTeaser/CategoryTeaser'
import DisplayGrid from 'components/DisplayGrid/DisplayGrid'
import Section from 'components/Section/Section'
import { Heading, HeadingMargin } from 'components/Ui-Kits/Text'
import { useCategories } from 'hooks/data/useCategories'
import { useAppTranslation } from 'i18n/hooks'
import { arrayOf, shape, string } from 'prop-types'
import { useMemo } from 'react'
import { number } from 'yup/lib/locale'

export const Category = ({ auctionCategories, auctionId, auctionNumber }) => {
  const { categories } = useCategories()
  const { t } = useAppTranslation()
  const sortedCategories = useMemo(
    () =>
      categories
        .filter(({ parentId, id }) => !parentId && auctionCategories.find(c => c.id == id))
        .sort(
          ({ id: id1 }, { id: id2 }) =>
            auctionCategories.find(c => c.id == id1)?.index - auctionCategories.find(c => c.id == id2)?.index
        ),
    [auctionCategories, categories]
  )

  return (
    <Section>
      <Heading color="primary" mt={HeadingMargin.NONE} mb={HeadingMargin.LARGE}>
        {t('auction')} {auctionNumber}
      </Heading>
      <DisplayGrid columns={4} rows={2}>
        {sortedCategories.map(({ id, label, image }) => (
          <CategoryTeaser key={id} text={label} background={image} path={`/auction/${auctionId}?category=${id}`} />
        ))}
      </DisplayGrid>
    </Section>
  )
}

Category.propTypes = {
  auctionCategories: arrayOf(shape({ id: string.isRequired, index: number.isRequired })).isRequired,
  auctionId: string.isRequired,
  auctionNumber: string.isRequired,
}
