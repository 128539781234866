import { Text } from 'components/Ui-Kits/Text'
import { func, oneOf, string } from 'prop-types'
import styled, { css } from 'styled-components'

export const Alert = ({ message, onClose, type = 'success', ...props }) => {
  return (
    <AlertWrap {...props} type={type}>
      <Text bold size={1}>
        {message}
      </Text>
      {onClose && <Close onClose={onClose}>&times;</Close>}
    </AlertWrap>
  )
}

Alert.propTypes = {
  type: oneOf(['success', 'danger']),
  message: string.isRequired,
  onClose: func,
}

const Close = styled.span`
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
  display: block;
`
const AlertWrap = styled.div`
  width: 100%;
  margin: 1.25rem auto;
  padding: 0.9rem;
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  box-shadow: 0 0 0.9375rem 0.3125rem #ccc;
  transition: opacity 0.4s ease-in-out;
  ${({ type }) => Type(type === 'danger' ? 'primary' : 'success')}
`
const Type = color => css`
  background-color: var(--color-${color}-light);
  border-left: 5px solid var(--color-${color});

  ${Close}, ${Text} {
    color: var(--color-${color}) !important;
  }
`
