import { Text, TextStyle } from 'components/Ui-Kits/Text'
import { useAppTranslation } from 'i18n/hooks'
import { array, bool, number, oneOfType, string } from 'prop-types'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { TaxonomyTypes } from '../../constants/categories'
import { useTaxonomies } from '../../hooks/useTaxonomies'
import Button from '../Button/Button'
import noImage from 'assets/images/no-image.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/pro-regular-svg-icons'
import { auctionStatuses } from 'constants/publishedProduct'
import qs from 'querystring'
import { Link } from 'react-router-dom'
import { AddToWishlist } from './AddToWishlist'
import { useEffect } from 'react'
import { useState } from 'react'

function ProductTeaser({
  name = 'Product name',
  price = 100,
  image = noImage,
  material,
  category = 'Category1',
  path,
  badgeLabel,
  finalPrice,
  tags = [],
  auctionStatus,
  auctionId,
  id,
  noPadding,
  startingPrice,
}) {
  const materials = useTaxonomies(TaxonomyTypes.MATERIAL)
  const [itemHasPrice, setItemHasPrice] = useState(false)
  const {
    t,
    i18n: { language },
  } = useAppTranslation()

  const getTagUrl = useCallback(id => `/auction/${auctionId}` + '?' + qs.stringify({ 'tags[]': [id] }), [auctionId])

  const tagElements = tags.map(({ label, id }) => (
    <TagText key={id} to={() => getTagUrl(id)} onClick={e => e.stopPropagation()}>
      {label[language]}
    </TagText>
  ))

  useEffect(() => {
    setItemHasPrice(Boolean(document.querySelector('.price-wrap')))
  }, [])

  const content = (
    <StyledProductTease noPadding={noPadding}>
      <ImageWrap noPadding={noPadding}>
        <ProductImage src={image} />
        {!!badgeLabel && (
          <OfferWrap bold color="primary">
            {badgeLabel}
          </OfferWrap>
        )}
        {id && <AddToWishlist id={id} auctionId={auctionId} />}
      </ImageWrap>
      <ProductName title={name}>{name}</ProductName>
      {!!finalPrice && (
        <Center className="price-wrap">
          <ProductPrice bold as="div">
            {t('hammerPrice')} <span>{finalPrice} €</span>
          </ProductPrice>
          <StartPrice bold as="div">
            {t('startingPrice')} {startingPrice} €
          </StartPrice>
        </Center>
      )}
      {!finalPrice && !itemHasPrice && <ProductPrice>{price.toLocaleString('fi-FI')} € </ProductPrice>}
      {!finalPrice && itemHasPrice && (
        <Center>
          <ProductPrice bold as="div">
            {auctionStatus === auctionStatuses.NOT_SOLD ? t('notSold') : <>&nbsp;</>}
          </ProductPrice>
          <StartPrice bold as="div">
            {t('startingPrice')} {startingPrice} €
          </StartPrice>
        </Center>
      )}
      <ProductMaterial>{!!material && materials.find(({ value }) => value == material)?.label}</ProductMaterial>
      <Button color="primary" secondary>
        {t('openProduct')}
      </Button>
      <ProductCategory>
        <span>{category}</span>
      </ProductCategory>
      <TagWrap>
        {!!tags?.length && <FontAwesomeIcon icon={faTag} />}
        <TagText color="primary" sizr={0.75} as="div">
          {tagElements}
        </TagText>
      </TagWrap>
      {auctionStatus === auctionStatuses.DISABLED && (
        <DisabledWrap>
          <Text bold color="primary">
            {t('itemIsntAvailable')}
          </Text>
        </DisabledWrap>
      )}
    </StyledProductTease>
  )

  if (!path) return content

  return <Link to={path}>{content}</Link>
}

ProductTeaser.propTypes = {
  name: string,
  price: number,
  image: string,
  material: oneOfType([string, number]),
  category: string,
  path: string,
  auctionStatus: string,
  badgeLabel: number,
  finalPrice: number,
  tags: array,
  auctionId: string,
  id: string,
  noPadding: bool,
  startingPrice: number,
}

export default ProductTeaser

const StyledProductTease = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: ${({ noPadding }) => (noPadding ? 1 : 2)}rem;
  background: var(--color-white);
  position: relative;
  p {
    font-family: var(--font-family-primary);
    font-weight: var(--font-weight-bold);
  }
`

const DisabledWrap = styled.div`
  background-color: var(--color-white);
  z-index: 10;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const ProductImage = styled.img`
  width: 100%;
`

const ProductName = styled.p`
  font-size: 1rem;
  color: var(--color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  height: 54px;
`

const ProductPrice = styled.p`
  font-size: 15px;
  color: var(--color-off-black);
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};

  & > span {
    font-size: 17px;
    color: var(--color-primary);
  }
`

const StartPrice = styled.p`
  font-size: 12px;
  margin-top: 4px;
  color: #000000;
  & ~ ${ProductPrice} {
    margin-bottom: 0;
  }
`

const ProductMaterial = styled.p`
  color: var(--color-off-black);
  font-size: 12px;
  line-height: 14px;
`

const ProductCategory = styled.p`
  background-color: var(--color-grey);
  color: var(--color-primary);
  font-size: 12px;
  line-height: 14px;
  padding: 6px 10px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  & > span {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
`
const ImageWrap = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
  ${({ noPadding }) =>
    noPadding &&
    `
    margin: -1rem -1rem 0;
    padding-bottom: calc(100% + 2rem);`}
`
const OfferWrap = styled.div`
  ${TextStyle}
  padding: 0.375rem 0.625rem;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color-grey);
  white-space: nowrap;
`
const TagWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  gap: 0.5rem;
  min-height: 20px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
`
const TagText = styled(Link)`
  ${TextStyle}
  flex: 1;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;

  & > &:after {
    content: ', ';
    display: inline;
  }

  & > &:last-child:after {
    display: none;
  }
`
const Center = styled.div`
  text-align: center;
`
