import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../data/firebase'

/**
 * @typedef {Object} CurrentUserReturn
 * @property {User|null} user
 * @property {boolean} loading
 *
 * @returns {CurrentUserReturn}
 */
export const useCurrentUser = () => {
  const [user, loading] = useAuthState(auth)

  return { user, loading }
}
