import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

function Section(props) {
  return (
    <StyledSection background={props.background} align={props.align}>
      {props.children}
    </StyledSection>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
  align: PropTypes.string,
}

export default Section

const StyledSection = styled.section`
  background-color: ${props => (props.background ? props.background : 'transparent')};
  width: 100%;
  max-width: 1200;
  margin: 0 auto;
  padding: 24px 0 32px;
  text-align: ${props => (props.align ? props.align : 'center')};

  @media (min-width: 1024px) {
    padding: 80px 0;
  }
`
