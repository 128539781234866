import { createContext, useCallback, useEffect, useState } from 'react'
import { any } from 'prop-types'
import { categoriesApiUrl } from '../constants/categories'

export const TaxonomiesContext = createContext()

function Taxonomies(props) {
  const [data, setData] = useState(null)

  const syncResult = useCallback(async () => {
    const result = await fetch(categoriesApiUrl, {
      method: 'GET',
    })
    const data = await result.json()
    setData(data)
  }, [])

  useEffect(() => {
    syncResult()
  }, [syncResult])

  return <TaxonomiesContext.Provider value={data}>{props.children}</TaxonomiesContext.Provider>
}

export default Taxonomies

Taxonomies.propTypes = {
  children: any,
}
