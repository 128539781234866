import { bool, oneOf } from 'prop-types'
import { css } from 'styled-components'

export const TextStyle = css`
  color: var(--color-${({ color }) => color});
  font-family: var(--font-family-secondary);
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ bold }) => bold && 'font-weight: bold;'}
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}
`
export const defaultPropTypes = {
  color: oneOf(['primary', 'grey', 'dark-gray', 'black', 'white']),
  bold: bool,
  cursor: bool,
}
