import { auctionStatuses } from 'constants/publishedProduct'
import { productConventer } from 'conventers/productConventer'
import { collection, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCollection, useCollectionData } from 'react-firebase-hooks/firestore'
import { db } from '../../../data/firebase'
import { range, random } from 'lodash'

/**
 * @typedef {Object} UseAuctionProductsReturn
 * @property {boolean} loading
 * @property {PublicProduct[]} products
 *
 * get auction products
 * @param {string} auctionId
 * @returns {UseAuctionProductsReturn} auction products
 */
export const useAuctionProducts = (auctionId, count) => {
  const [products, loading] = useCollection(
    count
      ? query(collection(db, `auctions/${auctionId}/products`), orderBy('sort', 'asc'), limit(count))
      : query(collection(db, `auctions/${auctionId}/products`), orderBy('sort', 'asc'))
  )
  const productsData = useMemo(() => {
    if (!products?.docs) return []

    return products.docs.map(prod => ({ id: prod.id, ...prod.data() }))
  }, [products])

  return { products: productsData, loading }
}

/**
 * @typedef {Object} UseAuctionProductsReturn
 * @property {boolean} loading
 * @property {PublicProduct[]} products
 *
 * get auction products
 * @param {string} auctionId
 * @returns {UseAuctionProductsReturn} auction products
 */
export const useAuctionCategoryRandomProducts = (auctionId, categoryId) => {
  const [q, setQ] = useState(undefined)

  const setQueryFunction = useCallback(async () => {
    const startIndex = await getDocs(
      query(
        collection(db, `auctions/${auctionId}/products`),
        orderBy('sort', 'asc'),
        where('category.id', '==', categoryId),
        limit(1)
      )
    ).then(snapshot => snapshot.docs[0]?.data()?.sort)
    const endIndex = await getDocs(
      query(
        collection(db, `auctions/${auctionId}/products`),
        orderBy('sort', 'desc'),
        where('category.id', '==', categoryId),
        limit(1)
      )
    ).then(snapshot => snapshot.docs[0]?.data()?.sort)

    if (endIndex - startIndex <= 3) {
      setQ(
        query(
          collection(db, `auctions/${auctionId}/products`),
          orderBy('sort', 'desc'),
          where('category.id', '==', categoryId)
        )
      )

      return
    }

    const sorts = range(0, 4).reduce(arr => {
      let item
      do {
        item = random(startIndex, endIndex)
      } while (arr.includes(item))

      arr.push(item)

      return arr
    }, [])

    setQ(
      query(
        collection(db, `auctions/${auctionId}/products`),
        where('sort', 'in', sorts),
        where('category.id', '==', categoryId)
      ).withConverter(productConventer)
    )
  }, [auctionId, categoryId])

  useEffect(() => {
    setQueryFunction()
  }, [setQueryFunction])

  const [products, loading] = useCollectionData(q)

  return { products, loading }
}

/**
 * @typedef {Object} UseAuctionProductsReturn
 * @property {boolean} loading
 * @property {PublicProduct[]} products
 *
 * get featured auction products
 * @param {string} auctionId
 * @returns {UseAuctionProductsReturn} auction products
 */
export const useFeaturedAuctionProducts = auctionId => {
  const [products, loading, error] = useCollection(
    query(
      collection(db, `auctions/${auctionId}/products`),
      orderBy('sort', 'asc'),
      where('featured', '==', true),
      limit(4)
    )
  )
  const productsData = useMemo(() => {
    if (!products?.docs) return []

    return products.docs.map(prod => ({ id: prod.id, ...prod.data() }))
  }, [products])

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return { products: productsData, loading }
}

/**
 * @typedef {Object} UseLiveAuctionCurrentProduct
 * @property {boolean} loading
 * @property {PublicProduct} product
 *
 * get auction products
 * @param {string} auctionId
 * @returns {UseLiveAuctionCurrentProduct} auction products
 */
export const useLiveAuctionCurrentProduct = auctionId => {
  const [{ docs: [product] } = { docs: [] }, loading] = useCollection(
    query(
      collection(db, `auctions/${auctionId}/products`),
      where('auctionStatus', 'in', [auctionStatuses.CURRENT, auctionStatuses.SOLD, auctionStatuses.NOT_SOLD]),
      orderBy('sort', 'desc'),
      limit(1)
    )
  )
  const productData = useMemo(() => {
    if (!product?.exists()) return null

    return { id: product.id, ...product.data() }
  }, [product])

  return { product: productData, loading }
}

/**
 * @typedef {Object} UseLiveAuctionCurrentProduct
 * @property {boolean} loading
 * @property {PublicProduct} product
 *
 * get auction products
 * @param {string} auctionId
 * @returns {UseLiveAuctionCurrentProduct} auction products
 */
export const useAuctionSublingProduct = (auctionId, sort, order = 'asc') => {
  const [products, loading, error] = useCollectionData(
    query(
      collection(db, `auctions/${auctionId}/products`),
      orderBy('sort', order),
      startAfter(sort),
      limit(1)
    ).withConverter(productConventer)
  )

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return { product: products?.[0], loading }
}
