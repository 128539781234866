import { number, oneOf } from 'prop-types'
import styled from 'styled-components'

const getWidth = col => {
  return `width: ${(col / 12) * 100}%;`
}
const getOffset = offset => {
  return `margin-left: ${(offset / 12) * 100}%;`
}

export const Col = styled.div`
  ${({ col }) => col && getWidth(col)}
  ${({ offset }) => offset && getOffset(offset)}
  padding-left: ${({ pl }) => pl}rem;
  padding-right: ${({ pr }) => pr}rem;
  padding-top: ${({ pt }) => pt}rem;
  padding-bottom: ${({ pb }) => pb}rem;
  @media screen and (max-width: 1024px) {
    ${({ md }) => md && getWidth(md)}
    ${({ mdOffset }) => mdOffset && getOffset(mdOffset)}
  }
  @media screen and (max-width: 768px) {
    ${({ sm }) => sm && getWidth(sm)}
    ${({ smOffset }) => smOffset !== undefined && getOffset(smOffset)}
  }
`

Col.propTypes = {
  ...Col.propTypes,
  col: oneOf([1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: oneOf([1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: oneOf([1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  pl: number,
  pr: number,
}

Col.defaultProps = {
  pl: 0.75,
  pr: 0.75,
  pt: 0.75,
  pb: 0.75,
}

export const Row = styled.div`
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-left: ${({ ml }) => ml}rem;
  margin-right: ${({ mr }) => mr}rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ mb }) => mb && `margin-bottom: ${mb}rem;`}
  ${({ mt }) => mt && `margin-top: ${mt}rem;`}
`

Row.propTypes = {
  ...Row.propTypes,
  ml: number,
  mr: number,
}

Row.defaultProps = {
  ml: -0.75,
  mr: -0.75,
}
