import styled from 'styled-components'
import holmastoLogoWhite from '../../assets/images/holmasto-logo-white.svg'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useAppTranslation } from 'i18n/hooks'
import { useEffect } from 'react'
import { footerMenuUrls, footerSEOMenuUrls } from 'constants/index'
import { Col, Row } from 'components/Ui-Kits/Grid'
import { Text } from 'components/Ui-Kits/Text'
import { decodeHtmlEntity } from 'utils'
import { getFooterContent } from 'helpers/getFooterContent'

function Footer() {
  const {
    i18n: { language },
  } = useAppTranslation()
  const [footerMenuItems, setFooterMenuItems] = useLocalStorage(`footer-menu-${language}`, [])
  const [footerSeoMenuItems, setFooterSeoMenuItems] = useLocalStorage(`footer-seo-menu-${language}`, [])

  useEffect(() => {
    const controller = new AbortController()
    fetch(footerMenuUrls[language], {
      method: 'GET',
      signal: controller.signal,
    })
      .then(result => result.json())
      .then(data => {
        if (!Array.isArray(data)) return
        setFooterMenuItems(
          data
            .filter(({ menu_item_parent }) => menu_item_parent === '0')
            .map(item => ({
              ...item,
              items: data.filter(({ menu_item_parent }) => parseInt(menu_item_parent) === item.ID),
            }))
        )
      })
      .catch(reason => {
        if (reason.name !== 'AbortError') {
          throw reason
        }
      })

    return () => {
      controller.abort()
    }
  }, [language, setFooterMenuItems])

  useEffect(() => {
    const controller = new AbortController()
    fetch(footerSEOMenuUrls[language], {
      method: 'GET',
      signal: controller.signal,
    })
      .then(result => result.json())
      .then(data => {
        if (!Array.isArray(data)) return
        setFooterSeoMenuItems(
          data
            .filter(({ menu_item_parent }) => menu_item_parent === '0')
            .map(item => ({
              ...item,
              items: data.filter(({ menu_item_parent }) => parseInt(menu_item_parent) === item.ID),
            }))
        )
      })
      .catch(reason => {
        if (reason.name !== 'AbortError') {
          throw reason
        }
      })

    return () => {
      controller.abort()
    }
  }, [language, setFooterSeoMenuItems])

  return (
    <footer>
      <FooterContainer>
        <Content>
          <Row>
            <StyledCol col={3} sm={12}>
              <FooterLogo>
                <a slot="logo" href="/" aria-label="Holmasto frontpage" rel="home">
                  <img src={holmastoLogoWhite} alt="" width="200px;" />
                </a>
              </FooterLogo>
            </StyledCol>
            <Col col={5} sm={12}>
              <FooterAddress>{getFooterContent(language)}</FooterAddress>
            </Col>
            <Col col={4} sm={12}>
              <FooterLinks>
                {footerSeoMenuItems.map(({ ID, title, url }) => (
                  <Link key={ID} href={url} aria-label="HolmastoSeo">
                    {decodeHtmlEntity(title)}
                  </Link>
                ))}
              </FooterLinks>
            </Col>
            <Col col={12}>
              <FooterMenu>
                {footerMenuItems.map(({ ID, title, url }) => (
                  <Text as="a" href={url} key={ID} color="white" size={1} bold>
                    {decodeHtmlEntity(title)}
                  </Text>
                ))}
              </FooterMenu>
            </Col>
          </Row>
        </Content>
      </FooterContainer>
      <div className="brand-navigation"></div>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer

const FooterContainer = styled.div`
  justify-self: end;
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 30px 15px;
  a {
    color: inherit;
  }
`

const Content = styled.div`
  max-width: 1200px;
  margin: auto;
  @media (min-width: 1440px) {
    padding: 80px 30px;
  }
`

const FooterLogo = styled.div`
  grid-area: logo;
  margin-bottom: 24px;
`

const FooterAddress = styled.div`
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 24px;
  & > P {
    margin-bottom: 17px;
  }
`

const FooterMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  text-transform: uppercase;
`

const FooterLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  flex-direction: column;
`

const Link = styled.a`
  color: #fff;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
`
const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`
