import { verifyPendingEmailUpdate } from 'cloudFunctions'
import { Loading, LoadingWrap } from 'components/Shared/Loading'
import { Heading, Text } from 'components/Ui-Kits/Text'
import { useAppTranslation } from 'i18n/hooks'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import bg from 'assets/images/bottom-banner-bg.jpg'
import { debounce } from 'lodash'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'components/Button'

export const VerifyEmail = () => {
  const ref = useRef(null)
  const [loading, setLoading] = useState(true)
  const { token } = useParams()
  const [minHeight, setMinHeight] = useState(0)
  const { t } = useAppTranslation()
  const calculateHeight = useCallback(() => {
    if (window.innerWidth < 760) return 0
    return window.innerHeight - ref.current.offsetTop
  }, [])
  const [state, setState] = useState('loading')
  const { headingText, subText } = useMemo(() => {
    switch (state) {
      case 'success':
        return {
          headingText: t('emailWasChanged'),
        }
      case 'error':
        return { headingText: t('tokenExpired'), subText: t('linkNotValid') }
      default:
        return {}
    }
  }, [t, state])

  useEffect(() => {
    setMinHeight(calculateHeight())
    const callback = debounce(() => setMinHeight(calculateHeight()), 400)

    window.addEventListener('resize', callback)

    return () => {
      window.removeEventListener('resize', callback)
      callback.cancel()
    }
  }, [calculateHeight])

  useEffect(() => {
    verifyPendingEmailUpdate(token)
      .then(() => setState('success'))
      .catch(() => setState('error'))
      .finally(() => setLoading(false))
  }, [token])

  return (
    <Section minHeight={minHeight} ref={ref}>
      {!!headingText && (
        <Heading color="primary" mb={0} mt={0}>
          {headingText}
        </Heading>
      )}
      {!!subText && <Text color="primary">{subText}</Text>}
      {!loading && (
        <Button as={Link} to="/" color="primary">
          {t('goToFrontPage')}
        </Button>
      )}

      {loading && (
        <LoadingWrap>
          <Loading />
        </LoadingWrap>
      )}
    </Section>
  )
}

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: ${({ minHeight }) => minHeight}px;
  background-image: url(${bg});
  gap: 24px;
  padding: 24px;
`
