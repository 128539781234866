import { approveProposal } from 'cloudFunctions'
import { Loading, LoadingWrap } from 'components/Shared/Loading'
import { Heading, Text } from 'components/Ui-Kits/Text'
import { useAppTranslation } from 'i18n/hooks'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import bg from 'assets/images/bottom-banner-bg.jpg'
import { debounce } from 'lodash'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'components/Button'
import { WEBSHOP_BASE_URL } from 'constants/index'

export const Approve = () => {
  const ref = useRef(null)
  const [loading, setLoading] = useState(true)
  const { token } = useParams()
  const [minHeight, setMinHeight] = useState(0)
  const { t } = useAppTranslation()
  const calculateHeight = useCallback(() => {
    if (window.innerWidth < 760) return 0
    return window.innerHeight - ref.current.offsetTop
  }, [])
  const [type, setType] = useState('')
  const { headingText, subText, button } = useMemo(() => {
    const button = (
      <Button as="a" href={WEBSHOP_BASE_URL} color="primary">
        {t('goToFrontPage')}
      </Button>
    )
    switch (type) {
      case 'product':
        return {
          headingText: t('youApprovedProducts'),
          subText: t('youApprovedProductsDescription').trim(),
          button: (
            <Button as={Link} to="/profile" color="primary">
              {t('goToMyHolmasto')}
            </Button>
          ),
        }
      case 'reception':
        return {
          headingText: t('youApprovedReception'),
          subText: t('weWillSendYouEmail'),
          button,
        }
      case 'error':
        return { headingText: t('tokenExpired'), subText: t('linkNotValid'), button }
      default:
        return {}
    }
  }, [t, type])

  useEffect(() => {
    setMinHeight(calculateHeight())
    const callback = debounce(() => setMinHeight(calculateHeight()), 400)

    window.addEventListener('resize', callback)

    return () => {
      window.removeEventListener('resize', callback)
      callback.cancel()
    }
  }, [calculateHeight])

  useEffect(() => {
    approveProposal(token)
      .then(({ data }) => {
        setType(data.type)
      })
      .catch(() => setType('error'))
      .finally(() => setLoading(false))
  }, [token])

  return (
    <Section minHeight={minHeight} ref={ref}>
      {!!headingText && (
        <Heading color="primary" mb={0} mt={0}>
          {headingText}
        </Heading>
      )}
      {!!subText && <StyledText color="primary">{subText}</StyledText>}
      {!loading && button}

      {loading && (
        <LoadingWrap>
          <Loading />
        </LoadingWrap>
      )}
    </Section>
  )
}

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: ${({ minHeight }) => minHeight}px;
  background-image: url(${bg});
  gap: 24px;
  padding: 24px;
`
const StyledText = styled(Text)`
  display: inline-block;
  text-align: center;
  white-space: pre-line;
`
