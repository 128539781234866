import { Alert } from 'components/Shared/Alert'
import { any } from 'prop-types'
import styled from 'styled-components'
import { v4 } from 'uuid'
const { createContext, useState, useCallback } = require('react')

export const AlertContext = createContext()

export const AlertProvider = ({ children }) => {
  const [messages, setMessages] = useState([])

  const removeMessage = useCallback(id => setMessages(messages => messages.filter(m => m.id !== id)), [])

  const alert = useCallback(
    (message, type = 'success') => {
      const id = v4()
      const timeout = setTimeout(() => removeMessage(id), 4000)
      const messageObject = {
        message,
        type,
        onClose: () => {
          removeMessage(id)
          clearTimeout(timeout)
        },
        id,
      }
      setMessages(messages => [...messages, messageObject])
    },
    [removeMessage]
  )

  return (
    <AlertContext.Provider value={alert}>
      {children}
      {!!messages.length && (
        <MessageWrap>
          {messages.map(item => (
            <Alert {...item} key={item.message} />
          ))}
        </MessageWrap>
      )}
    </AlertContext.Provider>
  )
}

const MessageWrap = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 20rem;
`

AlertProvider.propTypes = {
  children: any,
}
