import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { Text } from 'components/Ui-Kits/Text'
import { array, string } from 'prop-types'
import { useState } from 'react'
import { decodeHtmlEntity } from 'utils'
import { Icon, ListItem, SubList, SubListItem } from '../../Navigation.styled'

export const MainMenuItem = ({ items, title, url }) => {
  const [open, setOpen] = useState(false)

  const handleIconClick = e => {
    if (window.innerWidth > 1024) return
    e.preventDefault()

    setOpen(open => !open)
  }

  return (
    <ListItem>
      <Text as="a" href={url}>
        {decodeHtmlEntity(title)}
        {!!items.length && <Icon icon={faChevronDown} onClick={handleIconClick} />}
      </Text>
      {!!items.length && (
        <SubList open={open}>
          {items.map(({ ID, title, url }) => (
            <SubListItem key={ID}>
              <Text as="a" href={url}>
                {decodeHtmlEntity(title)}
              </Text>
            </SubListItem>
          ))}
        </SubList>
      )}
    </ListItem>
  )
}

MainMenuItem.propTypes = {
  items: array,
  title: string,
  url: string,
}
