import React from 'react'
import PropTypes, { string } from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router'

function CategoryTeaser({ text, background, path }) {
  const history = useHistory()

  return (
    <StyledCategoryTease background={background} onClick={() => path && history.push(path)}>
      <div>
        <h4>{text}</h4>
      </div>
      <div className="brand-navigation"></div>
    </StyledCategoryTease>
  )
}

CategoryTeaser.propTypes = {
  text: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  path: string,
}

export default CategoryTeaser

const StyledCategoryTease = styled.div`
  background-image: ${props => `url(${props.background})`};
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  color: var(--color-white);
  position: relative;
  background-color: var(--color-primary);
  > div:first-child {
    height: 188px;
    display: flex;
    justify-content: center;
    align-items: end;
    position: relative;
    z-index: 1;
  }

  &:after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.722), transparent 40%);
    display: block;
    height: 100%;
    height: calc(100% - 24px);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    content: '';
  }
`
