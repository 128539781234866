import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox } from 'components/Ui-Kits/Checkbox'
import { Text } from 'components/Ui-Kits/Text'
import { bool } from 'prop-types'
import { any, func, string } from 'prop-types'
import { useState } from 'react'
import styled from 'styled-components'

export const Accordion = ({ title, children, onChange, disabled, checked }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <AccordionWrap>
      <AccordionTitle>
        <Checkbox type="checkbox" onChange={onChange} marginBottom={0} disabled={disabled} checked={checked} />
        <TitleWrap onClick={toggleAccordion}>
          <Text>{title}</Text>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
        </TitleWrap>
      </AccordionTitle>
      {isOpen && <AccordionContent>{children}</AccordionContent>}
    </AccordionWrap>
  )
}

Accordion.propTypes = {
  title: string,
  children: any,
  onChange: func,
  disabled: bool,
  checked: bool,
}

const AccordionWrap = styled.div`
  width: 100%;
  margin-bottom: 10px;
`
const AccordionTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
`
const TitleWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`
const AccordionContent = styled.div`
  padding: 20px;
`
