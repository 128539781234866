import { oneOf } from 'prop-types'
import styled from 'styled-components'
import { defaultPropTypes, TextStyle } from './Shared'

export const Heading = styled.h1`
  ${TextStyle}
  font-size: ${({ size }) => size}rem;
  line-height: ${({ size }) => size}rem;
  margin-bottom: ${({ mb }) => mb}rem;
  margin-top: ${({ mt }) => mt}rem;
`

export const HeadingSize = Object.freeze({
  SMALLEST: 1.25,
  SMALL: 1.69,
  REGULAR: 2.5,
  BIG: 3.75,
})

export const HeadingMargin = Object.freeze({
  NONE: 0,
  SMALL: 1,
  REGULAR: 2,
  LARGE: 3,
})

Heading.propTypes = {
  ...Heading.propTypes,
  ...defaultPropTypes,
  mt: oneOf(Object.values(HeadingMargin)),
  mb: oneOf(Object.values(HeadingMargin)),
  size: oneOf(Object.values(HeadingSize)),
}

Heading.defaultProps = {
  color: 'black',
  mt: HeadingMargin.REGULAR,
  mb: HeadingMargin.REGULAR,
  size: HeadingSize.REGULAR,
}
