import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { string, object } from 'prop-types'
import styled from 'styled-components'
import { Heading, Paragraph, HeadingSize } from '../Ui-Kits/Text'
import { Link } from '../Ui-Kits/Text/Link'

export const Item = ({ description, title, link, icon }) => {
  return (
    <Wrap>
      <Icon icon={icon} />
      <StyledHeading size={HeadingSize.SMALL} color="primary">
        {title}
      </StyledHeading>
      <Paragraph>{description}</Paragraph>
      {link && (
        <div>
          <Link to={link} bold color="primary">
            LUE LISÄÄ
          </Link>
        </div>
      )}
    </Wrap>
  )
}

Item.propTypes = {
  description: string.isRequired,
  link: string,
  title: string.isRequired,
  icon: object.isRequired,
}

const Wrap = styled.div`
  text-align: center;
`
const StyledHeading = styled(Heading)`
  margin-bottom: 16px;
`

const Icon = styled(FontAwesomeIcon)`
  color: var(--color-primary);
  font-size: 2.5rem;
`
