import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { IS_PRODUCTION, FIREBASE_PROJECT_ID } from '../constants'

const firebaseConfig = JSON.parse(
  process.env.REACT_APP_CONFIG ||
    `{"projectId": "${FIREBASE_PROJECT_ID}", "apiKey": "123", "storageBucket": "${FIREBASE_PROJECT_ID}.appspot.com"}`
)

export const firebaseApp = initializeApp(firebaseConfig)

// Get a reference to the firestore
export const db = getFirestore(firebaseApp)

// Get a reference to the storage service, which is used to create references in your storage bucket
export const storage = getStorage(firebaseApp)

export const functions = getFunctions(firebaseApp, 'europe-west3')

export const auth = getAuth(firebaseApp)

if (!IS_PRODUCTION) {
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectStorageEmulator(storage, 'localhost', 9199)
  connectFunctionsEmulator(functions, 'localhost', 5001)
  connectAuthEmulator(auth, 'http://localhost:9099')
}
