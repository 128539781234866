import { Container } from 'components/Container'
import Section from 'components/Section/Section'
import { Heading, HeadingMargin } from 'components/Ui-Kits/Text'
import { useAppTranslation } from 'i18n/hooks'
import { useEffect, useState } from 'react'
import { articlesUrl } from 'constants/index'
import { Col, Row } from 'components/Ui-Kits/Grid'
import { Article } from './Article'

export const Articles = () => {
  const {
    t,
    i18n: { language },
  } = useAppTranslation()

  const [articles, setArticles] = useState([])
  const [finnishArticles, setFinnishArticles] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    fetch(articlesUrl.fi, {
      method: 'GET',
      signal: controller.signal,
    })
      .then(result => result.json())
      .then(data => {
        if (!Array.isArray(data)) return
        setFinnishArticles(data.slice(0, 3).map(({ translations }) => translations))
      })
      .catch(reason => {
        if (reason.name !== 'AbortError') {
          throw reason
        }
      })

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (!finnishArticles.length) return

    const controller = new AbortController()
    fetch(articlesUrl[language], {
      method: 'GET',
      signal: controller.signal,
    })
      .then(result => result.json())
      .then(data => {
        if (!Array.isArray(data)) return
        setArticles(
          data
            .filter(({ id }) => finnishArticles.map(item => item[language]).includes(id))
            .map(({ title, link, yoast_head_json, id, content }) => ({
              title: title.rendered,
              url: link,
              image: yoast_head_json?.og_image?.[0]?.url ?? content?.rendered?.match(/https:\/\/[^"]*\.jpg/)?.[0],
              id,
            }))
        )
      })
      .catch(reason => {
        if (reason.name !== 'AbortError') {
          throw reason
        }
      })

    return () => {
      controller.abort()
    }
  }, [language, setArticles, finnishArticles])

  return (
    <Section background="var(--color-grey)">
      <Heading color="primary" mt={HeadingMargin.NONE} mb={HeadingMargin.LARGE}>
        {t('articles')}
      </Heading>
      <Container maxWidth={1200}>
        <Row>
          {articles.map(({ id, ...article }) => (
            <Col key={id} col={4} sm={12}>
              <Article {...article} />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  )
}
