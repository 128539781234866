export const getFooterContent = lang => {
  switch (lang) {
    case 'en':
      return (
        <>
          <p>
            Service number&nbsp;<a href="tel:060096665">0600 96665</a> (1.78&euro;/min) |
          </p>
          <p>
            <a href="mailto:holmasto@holmasto.com">shop@holmasto.fi</a>
          </p>
          <p>Numis@holmasto.fi</p>
          <p>Auctions@holmasto.fi</p>
          <p>Holmasto Aleksanterinkatu 50 Helsinki 00100 Finland</p>
        </>
      )
    case 'sv':
      return (
        <>
          <p>
            Servicenummer <a href="tel:060096665">0600 96665</a> (1.78&euro;/min) |
          </p>
          <p>
            <a href="mailto:holmasto@holmasto.com">shop@holmasto.fi</a>
          </p>
          <p>Numis@holmasto.fi</p>
          <p>Auctions@holmasto.fi</p>
          <p>Holmasto Alexandersgatan 50 Helsingfors 00100 Finland</p>
        </>
      )
    default:
      return (
        <>
          <p>
            Palvelunumero&nbsp;<a href="tel:060096665">0600 96665</a> (1.92&euro;/min) |
          </p>
          <p>
            <a href="mailto:holmasto@holmasto.com">shop@holmasto.fi</a>
          </p>
          <p>numis@holmasto.fi</p>
          <p>auctions@holmasto.fi</p>
          <p>Holmasto Aleksanterinkatu 50 Helsinki 00100 Suomi</p>
          <p>Y-tunnus: 0888544-8</p>
        </>
      )
  }
}
