import { arrayOf, shape, string } from 'prop-types'
import styled from 'styled-components'
import { Heading, HeadingSize } from '../Ui-Kits/Text'
import { Link } from '../Ui-Kits/Text/Link'

export const TopBanner = ({ background, title, links, ...props }) => {
  return (
    <Banner background={background} {...props}>
      <Content>
        {links && !!links?.length && (
          <Bredcrumb>
            {links.map(({ link, label }, index, list) => (
              <BreadcrunbItem key={index}>
                <Link color="white" to={link} bold underline={index !== list.length - 1}>
                  {label}
                </Link>
              </BreadcrunbItem>
            ))}
          </Bredcrumb>
        )}
        <Heading size={HeadingSize.BIG} color="white">
          {title}
        </Heading>
      </Content>
    </Banner>
  )
}

TopBanner.propTypes = {
  background: string.isRequired,
  title: string.isRequired,
  links: arrayOf(
    shape({
      link: string,
      label: string,
    })
  ),
}

const Banner = styled.div`
  width: 100%;
  position: relative;
  background-image: url('${({ background }) => background}');
  height: 237px;
  background-size: cover;
  background-position: center;
  &:before,
  &:after {
    background-color: var(--color-light-black);
    width: 50%;
    top: 0;
    height: 100%;
    z-index: 0;
    content: '';
    display: block;
    right: 0;
    position: absolute;
  }

  &:before {
    right: auto;
    left: 0;
    opacity: 0.5;
  }
`
const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`
const Bredcrumb = styled.ul`
  max-width: 1031px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 25px;
`

const BreadcrunbItem = styled.li`
  display: inline;
  margin: 0;
  &:after {
    display: inline;
    color: var(--color-white);
    content: ' / ';
  }
  &:last-child:after {
    display: none;
  }
`
