import { number, string } from 'prop-types'
import { forwardRef } from 'react'
import styled from 'styled-components'
import { TextStyle } from '../Text'

export const Checkbox = forwardRef(({ label, marginBottom = 1.5, ...props }, ref) => {
  return (
    <Label marginBottom={marginBottom}>
      <Check ref={ref} {...props} type="checkbox" />
      <span>{label}</span>
    </Label>
  )
})

Checkbox.displayName = 'Checkbox'
Checkbox.propTypes = {
  label: string,
  marginBottom: number,
}

const Label = styled.label`
  ${TextStyle}
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ marginBottom }) => marginBottom}rem;
`
const Check = styled.input`
  width: 1rem;
  height: 1rem;
  border: 0;
  margin-right: 0.75rem;
`
