import { useEffect } from 'react'
import { useLocalStorage } from './useLocalStorage'

export const useCachedData = (data, key) => {
  const [cachedData, setCachedData] = useLocalStorage(key, data)

  useEffect(() => {
    if (data) setCachedData(data)
  }, [setCachedData, data])

  return cachedData
}
