import { doc } from 'firebase/firestore'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../data/firebase'
import { useCurrentUser } from '../useCurrentUser'

/**
 * @typedef {Object} UseCurrendUserDataReturn
 * @property {Profile|undefined} profile
 * @property {User|undefined} user
 * @property {boolean} loading
 *
 * @returns {UseCurrendUserDataReturn} user data
 */
export const useCurrentUserProfile = () => {
  const { user, loading: userLoading } = useCurrentUser()
  const [profile, loading, error] = useDocumentData(user?.uid ? doc(db, 'profiles', user.uid) : undefined)

  return { profile, loading: userLoading || loading, user, error }
}
