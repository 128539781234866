export const unverifiedUserMaxAmount = parseFloat(process.env.REACT_APP_MAX_UNVERIFIED_AMOUNT || '10000')
export const AccountType = Object.freeze({
  PERSONAL: 'personal',
  BUSINESS: 'business',
})

export const VerificationStatuses = {
  DRAFT: 'draft',
  BLOCKED: 'blocked',
  PENDING: 'pending',
  ACCEPTED: 'accepted',
}
