import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Text } from 'components/Ui-Kits/Text'
import { theme } from 'globalStyles'
import styled from 'styled-components'

export const NavigationWrap = styled.div`
  display: flex;
  background-color: var(--color-white);
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  @media (min-width: 1440px) {
    align-items: center;
  }
  @media (min-width: 1024px) {
    padding: 2rem;
  }
`

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 1.5rem;
`

export const Icon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`

export const Logo = styled.img`
  height: 25px;
  margin-left: 0;
  cursor: pointer;
  @media (min-width: 1024px) {
    height: 44px;
    margin: 0;
  }
  ${theme.breakpoints.phone} {
    height: 32px;
    margin-left: 1rem;
  }
`
export const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  background-color: var(--color-grey);

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 0;
    background-color: var(--color-white);
  }
`

export const SubList = styled.ul`
  padding: 0;
  display: ${({ open }) => (open ? 'block' : 'none')};
  top: 100%;
  background-color: var(--color-grey);
  list-style: none;
  z-index: 100;

  @media (min-width: 1024px) {
    padding: 1rem;
    white-space: nowrap;
    min-width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
  }
`

export const SubListItem = styled.li`
  margin: 0;

  ${Icon} {
    margin-left: 0.25rem;
  }

  & > ${Text} {
    font-size: 1rem;
    flex-direction: row;
    width: 100%;
    padding: 0.75rem 2.5rem;
    display: block;
    text-transform: uppercase;
    @media (min-width: 1024px) {
      width: auto;
      padding: 0;
      text-transform: none;
    }
  }

  @media (min-width: 1024px) {
    margin: 0.5rem 0;
  }
`

export const ListItem = styled.li`
  position: relative;
  margin: 0;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  ${Icon} {
    margin-left: 0.25rem;
  }
  & > ${Text} {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 1rem 1.5rem;

    @media (min-width: 1024px) {
      width: auto;
      padding: 0;
    }
  }

  @media (min-width: 1024px) {
    margin: 0 0.5rem;

    &:hover > ${SubList} {
      display: block;
    }
  }
`

export const MenusWrap = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column-reverse;
  background-color: var(--color-grey);
  padding-top: 1rem;

  @media (min-width: 1024px) {
    flex-direction: column;
    margin-left: 2rem;
    width: auto;
    flex: 1;
    align-items: flex-end;
    padding-top: 0;
    background-color: var(--color-white);
    display: flex;
  }

  @media (min-width: 1440px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const LogoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  & > ${List} {
    flex-direction: row;
    background-color: var(--color-white);
    align-items: center;

    & > ${ListItem} {
      margin-right: 1rem;
      &:last-child > ${Icon} {
        font-size: 2rem;
      }
    }
  }

  @media (min-width: 1024px) {
    width: auto;
    padding: 0rem;
    & > ${List} {
      display: none;
    }
  }
`
