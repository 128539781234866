import { createGlobalStyle } from 'styled-components'

const customMediaQuery = minWidth => `@media (min-width: ${minWidth}px)`

export const theme = {
  breakpoints: {
    custom: customMediaQuery,
    desktop: customMediaQuery(922),
    tablet: customMediaQuery(768),
    phone: customMediaQuery(576),
  },
  typography: {
    fontFamily: '"Atten New", "Roboto", "Helvetica", "Arial", sans-serif',
    fontFamilyKepler: '"Kepler Std", "Times New Roman", Times, serif',
    fontSize: 16,
    fontSizeMobile: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  spacing: x => `${x * 4}px`,
}

const GlobalStyles = createGlobalStyle`
  :root {
    --color-primary: #80032d;
    --color-primary-light: #F9F2F4;
    --color-primary-lighter: #D9B3C0;
    --color-white: #ffffff;
    --color-light-grey: #dddddd;
    --color-light-dark-grey: #8a8a8a;
    --color-grey: #f2f2f2;
    --color-dark-gray: #2c2a29;
    --color-off-black: #191919;
    --color-black: #000000;
    --color-light-black: #070b0c;
    --color-success: #1cd847;
    --color-success-light: #cff8d9;
    --color-pending: #ffc700;
    --color-green: #00FF6C;
    --color-green2: #118003;

    --font-family-primary: ${theme.typography.fontFamily};
    --font-family-secondary: ${theme.typography.fontFamilyKepler};

    --font-size-paragraph-L: 20px;
    --line-height-paragraph-L: 27px;
    --font-size-paragraph-M: 17px;
    --line-height-paragraph-M: 27px;
    --font-size-paragraph-S: 15px;
    --line-height-paragraph-S: 20px;

    --font-weight-regular: ${theme.typography.fontWeightRegular};
    --font-weight-medium: ${theme.typography.fontWeightMedium};
    --font-weight-bold: ${theme.typography.fontWeightBold};
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-family: var(--font-family-primary);
    font-size: ${theme.typography.fontSizeMobile}px;
    font-weight: ${theme.typography.fontWeightRegular};

    ${theme.breakpoints.tablet} {
      font-size: ${theme.typography.fontSize}px;
    }
  }

  body {
  }

  footer {
    font-family: ${theme.typography.fontFamily};
  }

  h1,
  h2,
  h3 {
    font-family: ${theme.typography.fontFamilyKepler};
    font-weight: ${theme.typography.fontWeightMedium};
  }

  h1 {
    font-size: 2.1875rem;
    line-height: 2.1875rem;

    ${theme.breakpoints.tablet} {
      font-size: 4.5rem;
      line-height: 4.625rem;
    }
  }

  h2 {
    font-size: 1.875rem;
    line-height: 1.875rem;

    ${theme.breakpoints.tablet} {
      font-size: 3.75rem;
      line-height: 3.75rem;
    }
  }

  h3 {
    font-size: 1.75rem;
    line-height: 1.75rem;

    ${theme.breakpoints.tablet} {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }

  h4 {
    font-family: ${theme.typography.fontFamily};
    font-size: 1.375rem;
    line-height: 1.6875rem;
    font-weight: ${theme.typography.fontWeightBold};
  }

  p {
    font-family: ${theme.typography.fontFamilyKepler};
    font-size: var(--font-size-paragraph-M);
    line-height: var(--line-height-paragraph-M);
  }

  a {
    font-family: ${theme.typography.fontFamily};
    text-decoration: none;
  }
`

export default GlobalStyles
