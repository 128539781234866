import { Button } from 'components/Button'
import Header from 'components/Header/Header'
import headerImage from 'assets/images/front-page-header-1.jpg'
import { bool, instanceOf, string } from 'prop-types'
import { useHistory } from 'react-router'
import { useAppTranslation } from 'i18n/hooks'
import { Paragraph } from 'components/Ui-Kits/Text'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { LiveBidBox } from './LiveBidBox'
import styled from 'styled-components'
import { Loading, LoadingWrap } from 'components/Shared/Loading'

export const Banner = ({
  id,
  auctionId,
  location,
  prebidStart,
  auctionStart,
  prebidEnd,
  liveAuctionStartedAt,
  liveAuctionEndedAt,
  loading,
}) => {
  const history = useHistory()
  const goToAuction = () => history.push(`/auction/${id}`)
  const {
    t,
    i18n: { language },
  } = useAppTranslation()
  const start = useMemo(() => {
    if (!prebidStart) return null

    return toDateTimeObject(prebidStart)
  }, [prebidStart])

  const end = useMemo(() => {
    if (!prebidEnd) return null

    return toDateTimeObject(prebidEnd)
  }, [prebidEnd])
  const date = useMemo(() => {
    if (!auctionStart) return null

    return toDateTimeObject(auctionStart)
  }, [auctionStart])
  const auctionIsRunning = useMemo(
    () => !!liveAuctionStartedAt && !liveAuctionEndedAt,
    [liveAuctionEndedAt, liveAuctionStartedAt]
  )

  const prebiodIsEnded = useMemo(() => dayjs().diff(prebidEnd) > 0, [prebidEnd])

  return (
    <Header background={headerImage}>
      {loading && (
        <LoadingWrap>
          <Loading />
        </LoadingWrap>
      )}
      {auctionId && (
        <h1>
          {t('auction')} {auctionId}
        </h1>
      )}
      {location && (
        <StyledParagraph size={1.25} mt={0} mb={0.75} color="write">
          {location?.[language]}
        </StyledParagraph>
      )}
      {start && (
        <Paragraph color="white" size={1.25} mt={0} mb={0.75}>
          {t('preBidStart', start)}
        </Paragraph>
      )}
      {end && (
        <Paragraph color="white" size={1.25} mt={0} mb={0.75}>
          {t('preBidUntil', end)}
        </Paragraph>
      )}
      {date && (
        <Paragraph color="white" size={1.25} mt={0} mb={0.75}>
          {t('auctionDate', date)}
        </Paragraph>
      )}
      {!auctionIsRunning && dayjs(prebidStart).diff(dayjs()) <= 0 && (
        <div>
          <Button color="white" onClick={goToAuction} large>
            {prebiodIsEnded ? t('catalogue') : t('catalogueAndPrebids')}
          </Button>
        </div>
      )}
      {auctionIsRunning && <LiveBidBox auctionId={id} />}
    </Header>
  )
}

const toDateTimeObject = dateObject => {
  if (!dateObject) return null

  const [date, time] = dayjs(dateObject).format('DD-MM-YYYY HH:mm').split(' ')

  return { date, time }
}

Banner.propTypes = {
  id: string,
  auctionId: string,
  location: string,
  prebidStart: instanceOf(Date),
  auctionStart: instanceOf(Date),
  prebidEnd: instanceOf(Date),
  liveAuctionEndedAt: instanceOf(Date),
  liveAuctionStartedAt: instanceOf(Date),
  loading: bool,
}

const StyledParagraph = styled(Paragraph)`
  white-space: pre-line;
`
