import { collection, query, where } from 'firebase/firestore'
import { useMemo } from 'react'
import { useCollectionOnce } from 'react-firebase-hooks/firestore'
import { documents } from '../../constants/firestore'
import { db } from '../../data/firebase'
import { useAppTranslation } from '../../i18n/hooks'

export const useCategories = () => {
  const {
    i18n: { language },
  } = useAppTranslation()
  const [categoryData, loading] = useCollectionOnce(
    query(collection(db, documents.CATEGORIES), where('deletedAt', '==', null))
  )

  const categories = useMemo(() => {
    if (!categoryData?.docs) return []

    return categoryData.docs.map(cat => ({
      id: cat.id,
      parentId: cat.data().parentId,
      label: cat.data().label[language],
      image: cat.data().image?.url,
    }))
  }, [categoryData, language])

  return { categories, loading }
}
