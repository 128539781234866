import { faSignIn, faSignOut, faUser } from '@fortawesome/pro-regular-svg-icons'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'components/Button'
import { Link } from 'components/Ui-Kits/Text/Link'
import { VerificationStatuses } from 'constants/user'
import { auth } from 'data/firebase'
import { signOut } from 'firebase/auth'
import { theme } from 'globalStyles'
import { useCurrentUserProfile } from 'hooks/data/useCurrentUserProfile'
import { useAppTranslation } from 'i18n/hooks'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { getWebshopUrl } from 'utils/getWebshopUrl'
import { Icon, ListItem, SubList as BaseSubList, SubListItem } from '../Navigation.styled'

export const Account = () => {
  const history = useHistory()
  const { user, loading, profile } = useCurrentUserProfile()
  const {
    t,
    i18n: { language },
  } = useAppTranslation()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!show) return

    const handle = () => {
      setShow(false)
    }
    window.document.body.addEventListener('click', handle)

    return () => {
      window.document.body.removeEventListener('click', handle)
    }
  }, [show])

  useEffect(() => {
    if (profile && profile.verificationStatus === VerificationStatuses.BLOCKED) {
      signOut(auth)
      history.push({
        pathname: '/auth/login',
        state: {
          blocked: true,
        },
      })
    }
  }, [profile, history])

  if (loading) return null

  if (user) {
    return (
      <>
        <ListItem>
          <Icon icon={faUser} onClick={() => history.push('/profile')} />
        </ListItem>
        <ListItem>
          <StyledButton onClick={() => signOut(auth)} color="primary">
            <span>{t('logOut')}</span>
            <Icon icon={faSignOut} />
          </StyledButton>
        </ListItem>
      </>
    )
  }

  return (
    <StyledListItem>
      <StyledButton color="primary" onClick={() => setShow(show => !show)}>
        <span>{t('logIn')}</span>
        <Icon icon={faSignIn} />
      </StyledButton>
      <SubList show={show}>
        <SubListItem>
          <Link as="a" href={getWebshopUrl(`${language}/my-account`)} target="_blank">
            {t('logInToTheOnlineStore')}
            <StyledIcon icon={faArrowRight} />
          </Link>
        </SubListItem>
        <SubListItem>
          <Link to="/auth/login">
            {t('logInToTheOnlineAuction')}
            <StyledIcon icon={faArrowRight} />
          </Link>
        </SubListItem>
      </SubList>
    </StyledListItem>
  )
}

const StyledButton = styled(Button)`
  font-size: 0.7rem;
  height: auto;
  padding: 0.5rem;
  line-height: 0.7rem;
  ${theme.breakpoints.phone} {
    font-size: 0.8rem;
    line-height: auto;
  }
  & > ${Icon} {
    margin-left: 0.5rem;
    font-size: 0.8rem;

    display: none;
    ${theme.breakpoints.phone} {
      display: inline-block;
    }
  }
`
const SubList = styled(BaseSubList)`
  right: 0;
  left: auto;
  position: absolute;
  top: 100%;
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 100%;
  padding: 1rem;
  z-index: 101;

  @media (min-width: 1024px) {
    font-size: 0.8rem;
    line-height: auto;
    display: none;
    width: auto;
    border-top: 1rem solid var(--color-white);
  }
`
const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
`

const StyledListItem = styled(ListItem)`
  position: static;

  @media (min-width: 1024px) {
    position: relative;
  }
`
