import { List, ListItem } from '../Navigation.styled'
import { Account } from './Account'
import { LanguageSwitcher } from './LanguageSwitcher'
import styled from 'styled-components'

export const RightMenu = () => {
  return (
    <StyledList>
      <LanguageSwitcher />
      <Account />
    </StyledList>
  )
}

const StyledList = styled(List)`
  display: flex;
  align-items: center;
  flex-direction: row;
  @media (max-width: 1024px) {
    padding: 0 1rem;
    & > ${ListItem} {
      display: none;
    }
  }
`
