export const downloadUrl = (url, name) => {
  const link = document.createElement('a')
  link.style.display = 'none'
  document.body.appendChild(link)
  link.href = url
  link.download = name
  link.click()
  link.remove()
}

export const downloadBufferJson = (file, name) => {
  const buffer = Buffer.from(file)
  const blob = new Blob([buffer], { type: 'application/pdf' })
  const objectURL = URL.createObjectURL(blob)
  downloadUrl(objectURL, name)
}

/** Decodes html entity */
export const decodeHtmlEntity = str => {
  // Creates div element
  const divElement = document.createElement('div')

  // Assigns inner html to string with entity
  divElement.innerHTML = str

  // Gets text from div which should have decoded entity
  const text = divElement.innerText

  // Destroy div element
  divElement.remove()

  return text
}
