import Button from 'components/Button/Button'
import nuggetsImage from 'assets/images/holmasto-gold-nuggets.jpeg'
import { Heading, Paragraph } from 'components/Ui-Kits/Text'
import MediaText from 'components/MediaText/MediaText'
import { useLastAuctions } from 'hooks/data/auction/useLastAuctions'
import { Banner } from './Banner'
import { Category } from './Categories'
import { Products } from './Products'
import { useAppTranslation } from 'i18n/hooks'
import { useGetTranslated } from 'hooks/useGetTranslated'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Articles } from './Articles'
import { useCachedData } from 'hooks/useCachedData'
import { Mission } from './Mission'

function FrontPage() {
  const { auctions: [firestoreAuction, previousAuction] = [], loading } = useLastAuctions(1)
  const cachedAuction = useCachedData(firestoreAuction, 'currentAuction')
  const auction = useMemo(() => firestoreAuction ?? cachedAuction, [firestoreAuction, cachedAuction])

  const firstTimeLoading = useMemo(() => !cachedAuction && loading, [loading, cachedAuction])

  const { t } = useAppTranslation()

  const auctionIsActive = useMemo(() => {
    if (!auction) return false

    return dayjs(auction?.startDatePreBid).diff(dayjs()) <= 0
  }, [auction])

  const getTranslated = useGetTranslated()

  return (
    <>
      <Banner
        id={auction?.id}
        location={auction?.description}
        auctionId={auction?.auctionId}
        prebidStart={auction?.startDatePreBid}
        prebidEnd={auction?.endDatePreBid}
        auctionStart={auction?.startDate}
        liveAuctionEndedAt={auction?.liveAuctionEndedAt}
        liveAuctionStartedAt={auction?.liveAuctionStartedAt}
        loading={firstTimeLoading}
      />

      {auction && auctionIsActive && (
        <Category auctionCategories={auction.categories} auctionId={auction.id} auctionNumber={auction.auctionId} />
      )}
      {!!auction?.id && auctionIsActive && (
        <MediaText backgroundImage={nuggetsImage} backgroundColor="var(--color-primary)">
          <Button color="white" secondary as={Link} to={`/auction/${auction.id}`} large>
            {t('listOfPriorOffers')}
          </Button>
          {!!auction?.catalogue && (
            <Button color="white" large as="a" href={getTranslated(auction.catalogue)} target="_blank">
              {t('downloadCatalogue')}
            </Button>
          )}
        </MediaText>
      )}
      {auction && auctionIsActive && <Products auctionId={auction.id} />}
      <Mission />
      {!!previousAuction && !auctionIsActive && (
        <MediaText backgroundImage={nuggetsImage} backgroundColor="var(--color-primary)">
          <Heading color="white">{t('previousAuctionN', { n: previousAuction.auctionId })}</Heading>
          <Paragraph color="white" mb={1}>
            {getTranslated(previousAuction.description)}
          </Paragraph>
          <Button color="white" large as={Link} to={`/auction/${previousAuction.id}`}>
            {t('moreProducts')}
          </Button>
        </MediaText>
      )}
      <Articles />
    </>
  )
}

export default FrontPage
