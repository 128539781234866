const { db } = require('data/firebase')
const { query, collection, where, orderBy, limit } = require('firebase/firestore')
const { useMemo } = require('react')
const { useCollection } = require('react-firebase-hooks/firestore')
/**
 * @typedef {Object} UseLastAuctionsReturn
 * @property {Auction[]} auctions
 * @property {boolean} loading
 *
 * @param {number} count
 * @returns {UseLastAuctionsReturn}
 */
export const useLastAuctions = count => {
  const [snapshot, loading] = useCollection(
    query(
      collection(db, 'auctions'),
      where('status', '==', 'published'),
      orderBy('startDate', 'desc'),
      where('deletedAt', '==', null),
      limit(count)
    )
  )

  const auctions = useMemo(() => {
    if (loading || !snapshot?.docs) return []

    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      startDatePreBid: doc.data().startDatePreBid?.toDate() || null,
      endDatePreBid: doc.data().endDatePreBid?.toDate() || null,
      startDate: doc.data().startDate?.toDate() || null,
    }))
  }, [loading, snapshot])

  return { auctions, loading }
}
