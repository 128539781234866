import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from '../../globalStyles'

function Header(props) {
  const { children, background } = props

  return (
    <HeaderContainer>
      <TextContainer>
        <div>{children}</div>
      </TextContainer>
      <Media background={background}></Media>
    </HeaderContainer>
  )
}

Header.propTypes = {
  children: PropTypes.node,
  background: PropTypes.string.isRequired,
}

export default Header

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  background-color: var(--color-primary);
  color: var(--color-white);
  position: relative;

  @media (min-width: 1024px) {
    flex-direction: row;
    margin-bottom: ${theme.spacing(20)}px;
    min-height: 400px;
  }
`

const TextContainer = styled.div`
  align-self: center;
  max-width: 600px;
  padding: 30px 15px;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    > div {
      display: grid;
      justify-content: center;
      padding: 12px 0;
    }
    p {
      font-family: var(--font-family-primary);
      font-size: var(--font-size-paragraph-L);
      line-height: var(--line-height-paragraph-L);
    }
  }
  @media (min-width: 1024px) {
    display: flex;
    justify-content: end;
    max-width: 100%;
    padding: 130px 100px;
    > div {
      max-width: 600px;
      text-align: left;
      > div {
        display: flex;
        justify-content: start;
      }
      p {
        max-width: 486px;
      }
    }
  }
`

const Media = styled.div`
  background-image: ${props => `url(${props.background})`};
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;

  @media (min-width: 768px) {
    min-height: 480px;
  }
`
