import { bool, number } from 'prop-types'
import styled from 'styled-components'
import { defaultPropTypes, TextStyle } from './Shared'

export const Text = styled.span`
  ${TextStyle}
  font-family: 'Atten New';
  font-size: ${({ size }) => size}rem;
  padding: none;
  border: 0;
  background-color: transparent;
  ${({ underline }) => underline && 'text-decoration: underline;'}
`
Text.propTypes = {
  ...defaultPropTypes,
  ...Text.propTypes,
  size: number,
  underline: bool,
}

Text.defaultProps = {
  size: 0.9375,
  color: 'black',
}
