import dayjs from 'dayjs'
import { useLastAuctions } from 'hooks/data/auction/useLastAuctions'
import { useLocation } from 'react-router'
import { AuctionBanner } from './AuctionBanner'
import { CountdownBanner } from './CountdownBanner'

export const Banner = () => {
  const { auctions: [auction] = [] } = useLastAuctions(1)
  const location = useLocation()
  const hidden =
    /\/live$/.test(location.pathname) ||
    !auction?.id ||
    !!auction?.liveAuctionEndedAt ||
    dayjs().diff(auction?.endDatePreBid) < 0

  if (hidden) return <></>

  if (auction.liveAuctionStartedAt) return <AuctionBanner id={auction.id} />

  return <CountdownBanner startDate={auction.startDate} id={auction.id} />
}
