import { Approve } from 'components/Approve'
import { NotFound } from 'pages/NotFound'
import React, { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import FrontPage from '../pages/FrontPage'
import { TextPage } from '../pages/TextPage'
import { VerifyEmail } from 'components/VerifyEmail'

const ProfileRoutes = lazy(() => import('./profile'))
const AuthRoutes = lazy(() => import('./auth'))
const VerifyRoutes = lazy(() => import('./verify'))
const AuctionRoutes = lazy(() => import('./auction'))
const ProductPreviewRoutes = lazy(() => import('./productPreview'))

const routes = [
  {
    path: '/',
    component: FrontPage,
  },
  {
    path: '/not-found',
    component: NotFound,
  },
  {
    path: '/text',
    component: TextPage,
  },
  {
    path: '/profile',
    component: ProfileRoutes,
    exact: false,
  },
  {
    path: '/auth',
    component: AuthRoutes,
    exact: false,
  },
  {
    path: '/verify',
    component: VerifyRoutes,
  },
  {
    path: '/auction',
    component: AuctionRoutes,
    exact: false,
  },
  {
    path: '/product/preview',
    component: ProductPreviewRoutes,
    exact: false,
  },
  {
    path: '/accept/:token',
    component: Approve,
    exact: true,
  },
  {
    path: '/verify-email/:token',
    component: VerifyEmail,
    exact: true,
  },
]

const Routes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Switch replace>
        {routes.map((props, index) => (
          <Route exact {...props} key={index} />
        ))}
        <Redirect to="/not-found" />
      </Switch>
    </React.Suspense>
  )
}

export default Routes
