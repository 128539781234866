import { Accordion } from 'components/Accordion'
import { Button } from 'components/Button'
import { Heading, HeadingSize, Paragraph, Text } from 'components/Ui-Kits/Text'
import { getCookie } from 'helpers/getCookie'
import { setCookie } from 'helpers/setCookie'
import { useAppTranslation } from 'i18n/hooks'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

export const Cookie = () => {
  const { t } = useAppTranslation()
  const [showOptions, setShowOptions] = useState(false)
  const [show, setShow] = useState(() => !getCookie('gds-consent'))
  const [values, setValues] = useState({ statistics: 0, marketing: 0 })
  const approveChosen = () => {
    setShow(false)
    setCookie('gds-consent', [1, 1, values.statistics, values.marketing].join(','), 720)
  }

  const approveAll = () => {
    setShow(false)
    setCookie('gds-consent', [1, 1, 1, 1].join(','), 720)
  }

  // Return null if cookie is already set
  if (!show) return null

  return createPortal(
    <Wrap>
      <PopUp>
        <Heading size={HeadingSize.SMALLEST} uppercase mb={3}>
          {t('cookieSettings')}
        </Heading>
        <Paragraph mb={2}>{t('cookieDescription')}</Paragraph>
        {showOptions && (
          <AccordionWrap>
            <Accordion title={t('necessary')} disabled checked>
              <Text>{t('necessaryCookieDescription')}</Text>
            </Accordion>
            <Accordion
              title={t('statistics')}
              onChange={e => setValues(values => ({ ...values, statistics: e.target.checked ? 1 : 0 }))}
            >
              <Text>{t('statisticsCookieDescription')}</Text>
            </Accordion>
            <Accordion
              title={t('marketing')}
              onChange={e => setValues(values => ({ ...values, marketing: e.target.checked ? 1 : 0 }))}
            >
              <Text>{t('marketingCookieDescription')}</Text>
            </Accordion>
          </AccordionWrap>
        )}
        <ButtonWrap>
          {!showOptions && (
            <Button color="primary" onClick={setShowOptions}>
              {t('editCookies')}
            </Button>
          )}
          {showOptions && (
            <Button color="primary" onClick={approveChosen}>
              {t('approveChosen')}
            </Button>
          )}
          <Button color="primary" onClick={approveAll}>
            {t('approveAll')}
          </Button>
        </ButtonWrap>
      </PopUp>
    </Wrap>,
    document.body
  )
}

const Wrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  padding: 20px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 10001;
`
const PopUp = styled.div`
  background: var(--color-white);
  width: 100%;
  max-width: min(645px, calc(100vw - 8px));
  height: auto;
  position: absolute;
  padding: 64px;
  box-sizing: border-box;
`
const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
`
const AccordionWrap = styled.div`
  margin-bottom: 2rem;
`
