import { db } from 'data/firebase'
import { doc } from 'firebase/firestore'
import { useProfile } from 'hooks/useProfile'
import { useMemo } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'

export const useWishlistItemExists = itemId => {
  if (!itemId) throw new Error('itemId is required')

  const { user, loading } = useProfile()
  const [wishlistDoc] = useDocument(!loading && user && doc(db, `profiles/${user.uid}/wishlist`, itemId))

  return useMemo(() => {
    return !!wishlistDoc?.exists()
  }, [wishlistDoc])
}
