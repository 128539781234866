import { number } from 'prop-types'
import styled from 'styled-components'
import { defaultPropTypes, TextStyle } from './Shared'

export const Paragraph = styled.p`
  ${TextStyle}
  font-size: ${({ size }) => size}rem;
  line-height: ${({ lineHeight }) => lineHeight}rem;
  margin-top: ${({ mt }) => mt ?? 0.5}rem;
  margin-bottom: ${({ mb }) => mb ?? 0.5}rem;
`

Paragraph.propTypes = {
  ...Paragraph.propTypes,
  ...defaultPropTypes,
  lineHeight: number,
}

Paragraph.defaultProps = {
  color: 'black',
  size: 1.0625,
  lineHeight: 1.6875,
}
